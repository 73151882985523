<template>
  <div class="slider banner-slider">
    <swiper :options="swiperOption" ref="swiperRef">
      <swiper-slide
        v-for="proposal in proposals"
        :key="proposal.id"
        :ref="swiperRef"
      >
        <ProposalImage :proposal="proposal" />
      </swiper-slide>
    </swiper>
    <div
      v-if="showBullets && proposals.length > 1"
      :id="`banner-pagination-${paginationClass}`"
      :class="`swiper-pagination banner-pagination-${paginationClass}`"
    ></div>
    <div
      v-if="showArrows && proposals.length > 1"
      :id="`banner-slider-prev-${paginationClass}`"
      :class="`swiper-button-prev banner-slider-prev-${paginationClass}`"
    ></div>
    <div
      v-if="showArrows && proposals.length > 1"
      :id="`banner-slider-next-${paginationClass}`"
      :class="`swiper-button-next banner-slider-next-${paginationClass}`"
    ></div>
  </div>
</template>
<script>
import ProposalImage from "./ProposalSliderImage.vue";

import deliveryReactive from "~/mixins/deliveryReactive";

export default {
  name: "ProposalSlider",
  props: {
    proposals: { type: Array, required: true },
    paginationClass: { type: String, default: "swiper-pagination-banner" },
    cols: { default: 1 },
    sm: { default: 1 },
    md: { default: 1 },
    lg: { default: 1 },
    xl: { default: undefined },
    showArrows: { type: Boolean, default: true },
    showBullets: { type: Boolean, default: true }
  },
  components: { ProposalImage },
  mixins: [deliveryReactive],
  data() {
    return {
      swiperRef: null,
      swiperOption: {
        spaceBetween: 20,
        autoplay: {
          disableOnInteraction: false
        },
        watchOverflow: true,
        loop: false,
        pagination: {
          el: `.banner-pagination-${this.paginationClass}`,
          clickable: true
        },
        navigation: {
          prevEl: `.banner-slider-prev-${this.paginationClass}`,
          nextEl: `.banner-slider-next-${this.paginationClass}`
        },
        breakpoints: {
          0: {
            slidesPerView: this.cols
          },
          600: {
            slidesPerView: this.sm
          },
          960: {
            slidesPerView: this.md
          },
          1264: {
            slidesPerView: this.lg
          },
          1904: {
            slidesPerView: this.xl || this.lg
          }
        }
      }
    };
  },
  methods: {
    reload() {
      if (this.$refs.swiperRef.swiperInstance) {
        this.$refs.swiperRef.swiperInstance.slideTo(0);
      }
    }
  }
};
</script>
