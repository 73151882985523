<template>
  <div class="category-custom">
    <v-container class="category-main">
      <Breadcrumb :items="breadcrumbs" />
    </v-container>
    <v-container
      v-if="certificatesProposals.length > 0 || headerProposals.length > 0"
    >
      <template v-if="certificatesProposals.length > 0">
        <ProposalSlider
          :title="certificatesTitle"
          :proposals="certificatesProposals"
        />
      </template>
      <component
        v-if="headerProposals.length > 0"
        :is="headerMode"
        :title="headerTitle"
        :proposals="headerProposals"
        paginationClass="pagination-header"
        :cols="12"
        :sm="6"
        :md="6"
        :lg="6"
      />
    </v-container>
    <v-container>
      <CategoryTitle :category="category" />
    </v-container>
    <v-container>
      <CategoryMainSlider
        v-if="category.children"
        :categories="category.children"
        :key="category.categoryId"
        paginationClass="subcategory"
      />
    </v-container>
    <v-container>
      <h3>Tutti i <strong>prodotti</strong></h3>

      <ProductListGrid
        v-bind:parentCategoryId="category.categoryId"
        :key="category.categoryId"
        @productsCount="updateCount"
        :hidePromoFilter="true"
        :hideSort="true"
      />
    </v-container>
    <v-container>
      <component
        v-if="footerProposals.length > 0"
        :is="footerMode"
        :title="footerTitle"
        :proposals="footerProposals"
        paginationClass="pagination-footer"
        :cols="1"
        :sm="2"
        :md="2"
        :lg="2"
      />
    </v-container>
  </div>
</template>
<style scoped lang="scss">
.center-img {
  margin-right: auto;
  margin-left: auto;
}
</style>
<script>
// @ is an alias to /src
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import ProductListGrid from "@/components/product/ProductListGrid.vue";
import ProposalSlider from "@/components/proposal/ProposalSlider.vue";
import CategoryMainSlider from "@/components/category/CategoryMainSlider.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";

import categoryMixins from "~/mixins/category";

import get from "lodash/get";
import { mapProposalComponent } from "~/service/ebsn";

export default {
  name: "CategoryCustom",
  mixins: [categoryMixins],
  data() {
    return {
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 20,
        watchOverflow: true,
        pagination: {
          el: ".firstCategory",

          clickable: true
        }
      },
      count: -1
    };
  },
  components: {
    CategoryTitle,
    CategoryMainSlider,
    Breadcrumb,
    ProductListGrid,
    ProposalSlider,
    ProposalProductListSlider: () =>
      import("@/components/proposal/ProposalProductSlider.vue"),
    ProposalProductGrid: () =>
      import("@/components/proposal/ProposalProductGrid.vue"),
    ProposalGrid: () => import("@/components/proposal/ProposalGrid.vue")
  },
  computed: {
    ...mapProposalComponent({
      header: "header",
      middle: "middle",
      footer: "footer",
      certificates: "Certificate Banner"
    }),
    breadcrumbs() {
      let breadCrumbs = [];
      let path = this.breadcrumb;
      if (path && path[0].to === "/" && path.length > 1) {
        return path;
      }
      breadCrumbs.push({
        to: { name: "Home" },
        text: "Homepage",
        exact: true
      });
      breadCrumbs.push({
        to: {
          name: "Category",
          params: {
            pathMatch: this.category.slug
          }
        },
        text: this.category.name,
        exact: true
      });
      if (this.categoryId) {
        let subCategory = this.category.children.find(
          cat => cat.categoryId == this.categoryId
        );
        breadCrumbs.push({
          to: {
            name: "Category",
            params: {
              pathMatch: this.category.slug
            },
            query: { categoryId: subCategory.categoryId }
          },
          text: subCategory.name,
          exact: true
        });
      }
      return breadCrumbs;
    }
  },
  methods: {
    updateCount(count) {
      this.count = count;
    }
  },
  metaInfo() {
    return {
      title: get(
        this.category,
        "metaData.category_seo.SEO_TITLE",
        this.category ? this.category.name : ""
      ),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(
            this.category,
            "metaData.category_seo.SEO_DESCRIPTION",
            this.$t("meta.category.firstLevel.description", [
              this.category.name
            ])
          )
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: get(this.category, "metaData.category_seo.SEO_KEYWORDS")
        },
        // Open Graph Metas
        {
          property: "og:locale",
          content: "it_IT"
        },
        {
          property: "og:title",
          content: get(
            this.category,
            "metaData.category_seo.SEO_TITLE",
            this.category ? this.category.name : ""
          )
        },
        {
          property: "og:type",
          content: "product.group"
        },
        {
          property: "og:url",
          content: location.href
        },
        {
          property: "og:image",
          content: "https://" + window.location.host + "/logo/social-logo.png"
        },
        {
          property: "og:image:alt",
          content: get(
            this.category,
            "metaData.category_seo.SEO_DESCRIPTION",
            this.$t("meta.category.firstLevel.description", [
              this.category.name
            ])
          )
        },
        {
          property: "og:site_name",
          content: "DupliClick"
        },
        // Twitter Metas
        {
          name: "twitter:card",
          content: "summary_large_image"
        },
        {
          name: "twitter:title",
          content: get(
            this.category,
            "metaData.category_seo.SEO_TITLE",
            this.category ? this.category.name : ""
          )
        },
        {
          name: "twitter:image",
          content: "https://" + window.location.host + "/logo/social-logo.png"
        },
        {
          name: "twitter:url",
          content: location.href
        }
      ]
    };
  }
  // async mounted() {

  // }
};
</script>
