<template>
  <v-breadcrumbs class="hidden-sm-and-down" :items="items"></v-breadcrumbs>
</template>
<style lang="scss">
.v-breadcrumbs {
  padding-left: 0 !important;
  li {
    font-size: 12px;
  }
}
.v-breadcrumbs__item {
  color: $text-color !important;
}
.v-breadcrumbs__item--disabled {
  color: $text-color !important;
  font-weight: bold;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .v-breadcrumbs {
    padding: 10px 0 0px 0;
    padding-left: 14px !important;
  }
}
</style>
<script>
export default {
  name: "Breadcrumb",
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  jsonld() {
    const items = this.items.map((item, index) => ({
      "@type": "ListItem",
      position: index + 1,
      item: {
        "@id": item.to,
        name: item.text
      }
    }));
    return {
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: items
    };
  }
};
</script>
